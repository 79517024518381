import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DescriptionText } from "./Typography";

/* eslint-disable no-octal-escape */
export const BackToTop = () => {
  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const [visible, setVisible] = useState(true);
  const bodyElement = document.body;

  const resizeObserver = new ResizeObserver(() => {
    setVisible(bodyElement.clientHeight > window.innerHeight);
  });

  resizeObserver.observe(bodyElement);

  if (!visible) {
    return <></>;
  }
  return (
    <div className="sm:hidden text-right pb-[31px]">
      <span className="text-white cursor-pointer" onClick={scrollToTop}>
        <DescriptionText
          text="Back To Top"
          animations="None"
          color="Plain"
          contentAfter="fa-arrow-up-long"
        />
      </span>
    </div>
  );
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  return null;
}
