/* eslint-disable no-octal-escape */
import { Person } from "../data/persons";
import "../assets/css/PersonAccordian.css";
import {
  BoldDescriptionText,
  DescriptionText,
  HeroDescriptionText,
} from "./Typography";
import { underlineStyle } from "./animations";

export const PersonAccordian = (props: { person: Person }) => {
  const minimizeAccordian = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event?.currentTarget?.parentElement?.toggleAttribute("open");
  };

  const notableCredits = props.person.noteableCredits.map((credit, index) => (
    <div className="pt-[21px] lg:pt-[32px] uppercase" key={index}>
      <BoldDescriptionText text={credit} color="Plain" animations="None" />
    </div>
  ));

  return (
    <div className="pb-[31px] lg:pb-[91px]">
      <div className="lg:pb-[14px]">
        <DescriptionText
          text={props.person.tittle}
          color="Plain"
          animations="None"
        />
      </div>
      <details className="group border-white border-b-[1px] border-solid focus:outline-none">
        <summary className="flex justify-between hover:cursor-pointer focus:outline-none">
          <span className="group-open:text-orange-400">
            <HeroDescriptionText
              text={props.person.name}
              animations="None"
              color="White"
            />
          </span>
          <span className="self-end group-open:hidden">
            <DescriptionText
              text="Learn More"
              color="Plain"
              animations="None"
              contentAfter="fa-arrow-down-long"
            />
          </span>
        </summary>
        <div className="grid grid-cols-1 lg:grid-cols-[55%_33%] gap-x-[12%] gap-y-[49px] lg:gap-y-[0px] justify-between pt-[30px] pb-[30px] lg:pt-[48px] lg:pb-[55px]">
          <div>
            <DescriptionText
              text={props.person.bio}
              color="Plain"
              animations="None"
            />
            <br></br>
            <br></br>
            <div className="hover:cursor-pointer relative w-max">
              <a
                className={underlineStyle}
                href={props.person.imdbLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DescriptionText
                  text="VIEW IMDb"
                  color="Plain"
                  animations="None"
                />
              </a>
            </div>
          </div>
          <div className="flex lg:justify-end">
            <div className="w-fit">
              <DescriptionText
                text="Notable Credits"
                color="Plain"
                animations="None"
              />
              {notableCredits}
            </div>
          </div>
        </div>
        <div
          className="basis-[100%] text-orange-400 cursor-pointer text-right pb-[2px] lg:pb-[29px] text-[14px]"
          onClick={minimizeAccordian}
        >
          <i className="text-[10px] fa-solid fa-arrow-up-long cursor-pointer" />
        </div>
      </details>
    </div>
  );
};
