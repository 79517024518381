import { useEffect, useRef } from "react";

export type VideosTypes = {
  videoMP4: any;
  videoWebM: any;
};

export const Video = (props: VideosTypes) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  // Set up an event listener for the `timeupdate` event
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  });

  return (
    <main
      id="mainContainer"
      className="absolute top-0 left-0 -z-10 h-screen w-screen"
    >
      <div className="relative h-full w-full flex justify-center">
        <video
          className="w-screen h-screen object-cover animate-fade-in-6"
          ref={videoRef}
          preload="auto"
          autoPlay={false}
          loop={false}
          playsInline={true}
          muted={true}
        >
          <source src={props.videoMP4} type="video/mp4" />
          <source src={props.videoWebM} type="video/webm" />
          <p>Your browser does not support embedded videos</p>
        </video>
      </div>
    </main>
  );
};
