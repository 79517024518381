import React from "react";
import { NoiseEffect } from "./animations";
import { BackToTop } from "./BackToTop";

export const MainContent = (props: {
  backToTop: boolean;
  children: React.ReactNode;
}) => {
  const BackToTopElement = props.backToTop ? <BackToTop /> : <React.Fragment />;

  return (
    <>
      <main id="mainContainer" className="h-full">
        <div className="text-white m-auto">{props.children}</div>
        {BackToTopElement}
      </main>
      <NoiseEffect />
    </>
  );
};
