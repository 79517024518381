export const underlineStyle =
  "before:h-[2px] before:bottom-0 before:left-0 before:absolute before:w-full before:rounded-3xl before:bg-orange-500 hover:text-orange-400";

export const underlineAnimation =
  "before:h-[2px] before:bottom-0 before:left-0 before:absolute before:w-full before:rounded-3xl before:bg-orange-500 before:scale-x-0 before:ease-linear before:duration-300 before:invisible hover:before:visible hover:before:scale-x-100 hover:text-orange-400";

export const NoiseEffect = () => {
  return (
    <div className="hidden sm:block absolute z-50 pointer-events-none left-0 top-0 right-0 bottom-0 w-full h-full overflow-hidden">
      <div className="noise sm:animate-noise brightness-50 opacity-10 h-[calc(100%_+_100vw)] w-[calc(100%_+_100vw)] m-[-10rem]" />
    </div>
  );
};
