import React from "react";
import { HeroText } from "../components/Typography";
import { Video, VideosTypes } from "../components/Video";
import { isMobile } from "../util/isDevice";

export const Home = () => {
  React.useEffect(() => {
    document.title = "York and Wilder";
  }, []);

  let videos: VideosTypes;
  if (isMobile()) {
    videos = {
      videoMP4: require("../assets/video/YW_SFX_mobile.mp4"),
      videoWebM: require("../assets/video/YW_SFX_mobile.webm"),
    };
  } else {
    videos = {
      videoMP4: require("../assets/video/YW_SFX.mp4"),
      videoWebM: require("../assets/video/YW_SFX.webm"),
    };
  }

  return (
    <>
      <div className="h-0 overflow-hidden">
        <HeroText
          text="York And Wilder Video"
          color="GradientOnHover"
          animations="None"
        />
      </div>

      <Video {...videos} />
    </>
  );
};
