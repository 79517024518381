export const toggleBurgerPopover = () => {
  menuPopoverManipulator("#mobileMenuPopover");
  mobileMenuFooterManipulator();
  mainContainerManipulator();
  menuManipulator();
};

export const closeBurgerPopover = () => {
  // Determine if certain classNames appear. Those classNames will determine if popover exists
  const mobileMenuPopover = document.querySelector("#mobileMenuPopover");

  if (mobileMenuPopover && mobileMenuPopover.classList.contains("!z-10")) {
    menuPopoverManipulator("#mobileMenuPopover");
    menuManipulator();
    mobileMenuFooterManipulator();
    mainContainerManipulator();
  }
};

export const openProjectPopover = () => {
  // Determine if certain classNames appear. Those classNames will determine if popover exists
  const projectMenuPopover = document.querySelector("#projectMenuPopover");
  if (projectMenuPopover && !projectMenuPopover.classList.contains("!z-10")) {
    menuPopoverManipulator("#projectMenuPopover");
    headerBackgroundManipulator();
  }
};

export const closeProjectPopover = () => {
  // Determine if certain classNames appear. Those classNames will determine if popover exists
  const projectMenuPopover = document.querySelector("#projectMenuPopover");
  if (projectMenuPopover && projectMenuPopover.classList.contains("!z-10")) {
    menuPopoverManipulator("#projectMenuPopover");
    headerBackgroundManipulator();
  }
};

const menuManipulator = () => {
  const menuXIcon = document.querySelector("#mobile-x-arrow");
  if (menuXIcon) {
    menuXIcon.classList.toggle("hidden");
  }

  const menuBurgerIconList = document.querySelectorAll("#mobile-menu");
  if (menuBurgerIconList && menuBurgerIconList.length > 0) {
    menuBurgerIconList.forEach((menuBurgerIcon) => {
      menuBurgerIcon.classList.toggle("hidden");
    });
  }
};

const menuPopoverManipulator = (
  type: "#projectMenuPopover" | "#mobileMenuPopover"
) => {
  // Create space and bring popover to front
  const menuPopover = document.querySelector(type);
  if (menuPopover) {
    menuPopover.classList.toggle("!z-10");
    menuPopover.classList.toggle("!flex");
  }
};

// Create footer space
const mobileMenuFooterManipulator = () => {
  const footerNav = document.querySelector("#footerNav");
  if (footerNav) {
    footerNav.classList.toggle("hidden");
  }
};

// hide main content from taking space
const mainContainerManipulator = () => {
  const mainContainer = document.querySelector("#mainContainer");
  if (mainContainer) {
    mainContainer.classList.toggle("hidden");
  }
};

const headerBackgroundManipulator = () => {
  const header = document.querySelector("header");
  if (header) {
    header.classList.toggle("bg-black");
  }
};
