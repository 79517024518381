import React from "react";
import { MainContent } from "../components/MainComponent";
import { PersonAccordian } from "../components/PersonAccordian";
import { HeroDescriptionText } from "../components/Typography";
import { LaurenRanzino, MarnieSirota, MattSprouse } from "../data/persons";

export const About = () => {
  React.useEffect(() => {
    document.title = "About | York and Wilder";
  }, []);
  return (
    <MainContent backToTop={true}>
      <div className="flex flex-col flex-nowrap pt-[14px] lg:pt-[53px] ">
        <div className="text-center pb-[14px] lg:pb-[30px]">
          <div className="relative overflow-hidden w-fit m-auto uppercase">
            <HeroDescriptionText
              text="Who We Are"
              animations="animate-up-2"
              color="Gradient"
            />
          </div>
        </div>
        <div className="grow pb-[53px] lg:pb-[81px] text-center animate-fade-in-4">
          <HeroDescriptionText
            text="A turn-key television production company servicing Streamers, Broadcasters, and Cable; York & Wilder handles all aspects of creative services and production services from ideation and concept development through field production, postproduction, and final delivery."
            animations="None"
            color="White"
          />
        </div>
        <div className="pb-[18px] lg:pb-8">
          <div className="overflow-hidden text-center relative w-fit m-auto uppercase">
            <HeroDescriptionText
              text="Leadership"
              color="Gradient"
              animations="animate-up-2"
            />
          </div>
        </div>
        <PersonAccordian person={MattSprouse} />
        <PersonAccordian person={MarnieSirota} />
        <PersonAccordian person={LaurenRanzino} />
      </div>
    </MainContent>
  );
};
