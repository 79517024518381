export type HeroTextProp = {
  text: string;
  color: "Plain" | "GradientOnHover" | "Gradient";
  animations: "None" | "animate-up-2";
};

export const HeroText = (props: HeroTextProp) => {
  /*
   * Consistent
   * font-family: Futura;
   * font-weight: 700;
   * letter-spacing: 0.02em;
   * text-align: left;
   *
   * Desktop
   * font-size: 115px;
   * line-height: 117px;
   *
   * Mobile
   * font-size: 58px;
   * line-height: 54px;
   */
  let heroTextClass =
    "uppercase font-Futura font-bold tracking-[.02em] text-[58px] lg:text-[115px] leading-[54px] lg:leading-[117px]";

  /*
   * Allow hero text to be gradient, or gradient on hover
   */
  if (props.color === "Gradient") {
    heroTextClass =
      heroTextClass + " bg-clip-text bg-textGradient text-transparent";
  } else if (props.color === "GradientOnHover") {
    heroTextClass =
      heroTextClass +
      " hover:cursor-pointer hover:bg-clip-text hover:bg-textGradient hover:text-transparent";
  }

  /*
   * Allow text to be animated
   */
  if (props.animations !== "None") {
    heroTextClass = heroTextClass + " " + props.animations;
  }
  return <h1 className={heroTextClass}>{props.text}</h1>;
};

export type HeroDescriptionTextProp = {
  text: string;
  animations:
    | "None"
    | "animate-up-2"
    | "animate-up-4"
    | "animate-up-6"
    | "animate-up-8 pause"
    | "animate-up-8";
  color: "White" | "text-orange-400" | "Gradient";
};

export const HeroDescriptionText = (props: HeroDescriptionTextProp) => {
  /*
   * Consistent
   * font-family: Futura;
   * font-weight: 700;
   * letter-spacing: 0.02em;
   * text-align: center;
   *
   * Desktop
   * font-size: 30px;
   * line-height: 40px;
   *
   * Mobile
   * font-size: 20px;
   * line-height: 28px;
   */
  let heroTextClass =
    "font-Futura font-bold tracking-[.02em] text-[20px] lg:text-[30px] leading-[28px] lg:leading-[40px]";

  /*
   * Allow text to be animated
   */
  if (props.animations !== "None") {
    heroTextClass = heroTextClass + " " + props.animations;
  }

  if (props.color !== "White") {
    if (props.color === "Gradient") {
      heroTextClass =
        heroTextClass + " bg-clip-text bg-textGradient text-transparent";
    } else {
      heroTextClass = heroTextClass + " " + props.color;
    }
  }

  return <h3 className={heroTextClass}>{props.text}</h3>;
};

export type HeroSubDescriptionTextProp = {
  text: string;
};

export const HeroSubDescriptionText = (props: HeroSubDescriptionTextProp) => {
  /*
   * Consistent
   * font-family: 'Futura';
   * font-weight: 700;
   * font-size: 25px;
   * line-height: 35px;
   * letter-spacing: 0.02em;
   */
  let heroSubDescriptionTextClass =
    "font-Futura font-bold text-[25px] leading-[35px] tracking-[.02em] hover:cursor-pointer hover:bg-clip-text hover:bg-textGradient hover:text-transparent";

  return <h3 className={heroSubDescriptionTextClass}>{props.text}</h3>;
};

export type DescriptionTextProp = {
  text: string;
  color: "Plain" | "Color";
  animations:
    | "None"
    | "animate-up-2"
    | "animate-up-4"
    | "animate-up-4 pause"
    | "animate-up-6"
    | "animate-up-6 pause"
    | "animate-up-8"
    | "animate-up-8 pause";
  contentAfter?: never | string;
};

/*
 * Small descriptions and links
 */
export const DescriptionLinksText = (props: DescriptionTextProp) => {
  /*
   * Consistent
   * font-family: Neue Haas Unica W1G;
   * font-weight: 400;
   * letter-spacing: 0.1em;
   * text-align: left;
   * font-size: 14px;
   * line-height: 21px;
   */
  let descriptionTextClass =
    "font-NeueHaasUnicaW1G font-normal tracking-[.01em] text-[14px] leading-[21px]";

  /*
   * Allow hero text to be gradient, or gradient on hover
   */
  if (props.color === "Color") {
    descriptionTextClass = descriptionTextClass + " text-orange-400";
  }

  /*
   * Allow text to be animated
   */
  if (props.animations !== "None") {
    descriptionTextClass = descriptionTextClass + " " + props.animations;
  }

  if (props.contentAfter) {
    const contentAfterClass = `text-[10px] fa-solid ${props.contentAfter} cursor-pointer`;
    return (
      <p className={descriptionTextClass}>
        {props.text} <i className={contentAfterClass} />
      </p>
    );
  }

  return <p className={descriptionTextClass}>{props.text}</p>;
};

export const DescriptionText = (props: DescriptionTextProp) => {
  /*
   * Consistent
   * font-family: Neue Haas Unica W1G;
   * font-weight: 400;
   * letter-spacing: 0.1em;
   * text-align: left;
   *
   * Desktop
   * font-size: 18px;
   * line-height: 28px;
   *
   * Mobile
   * font-size: 14px;
   * line-height: 21px;
   */
  let descriptionTextClass =
    "font-NeueHaasUnicaW1G font-normal tracking-[.01em] text-[14px] leading-[21px] lg:text-[18px] lg:leading-[28px]";

  /*
   * Allow hero text to be gradient, or gradient on hover
   */
  if (props.color === "Color") {
    descriptionTextClass = descriptionTextClass + " text-orange-400";
  }

  /*
   * Allow text to be animated
   */
  if (props.animations !== "None") {
    descriptionTextClass = descriptionTextClass + " " + props.animations;
  }

  if (props.contentAfter) {
    const contentAfterClass = `text-[10px] fa-solid ${props.contentAfter} cursor-pointer`;
    return (
      <p className={descriptionTextClass}>
        {props.text} <i className={contentAfterClass} />
      </p>
    );
  }

  return <p className={descriptionTextClass}>{props.text}</p>;
};

export const BoldDescriptionText = (props: DescriptionTextProp) => {
  /*
   * Consistent
   * font-family: Neue Haas Unica W1G;
   * font-weight: 400;
   * letter-spacing: 0.1em;
   * text-align: left;
   * font-size: 14px;
   * line-height: 21px;
   *
   * Desktop
   * font-size: 18px;
   * line-height: 28px;
   *
   * Mobile
   * font-size: 14px;
   * line-height: 21px;
   */
  let heroTextClass =
    "font-NeueHaasUnicaW1G font-bold tracking-[.01em] text-[14px] leading-[21px] lg:text-[18px] lg:leading-[28px]";

  /*
   * Allow hero text to be gradient, or gradient on hover
   */
  if (props.color === "Color") {
    heroTextClass = heroTextClass + " text-orange-400";
  }

  /*
   * Allow text to be animated
   */
  if (props.animations !== "None") {
    heroTextClass = heroTextClass + " " + props.animations;
  }

  return <p className={heroTextClass}>{props.text}</p>;
};
