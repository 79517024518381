export const photoFolder = "projectPhotos/";

export type ProjectData = {
  title: string;
  dropDownOrder: number;
  displayedOrder: number;
  titleFragments: string[];
  url: string;
  imgUrl: string;
  releaseDate: string;
  duration: string;
  synopsis: string;
  executiveProducers?: string[];
  directors?: string[];
  productionDesign?: string[];
  directorsOfPhotography?: string[];
  culinaryProducer?: string[];
};

export const caseyAnthonyProject: ProjectData = {
  title: "Casey Anthony's Parents Speak",
  dropDownOrder: 5,
  displayedOrder: 3,
  titleFragments: ["Casey", "Anthony's", "Parents", "Speak"],
  url: "casey-anthony's-parents-speak",
  imgUrl: "CaseyAnthony.jpg",
  duration: "60 x 2",
  releaseDate: "2018",
  synopsis:
    "Ten years after the tragic disappearance and mysterious death of three-year-old Caylee Anthony, her grandparents, George and Cindy Anthony, open up to Elizabeth Vargas about their lives. Along the way George and Cindy revisit locations that have come to define their lives, from the woods where Caylee’s remains were found, to the hotel where George almost ended it all. And for the first time ever, cameras follow as they shed light on the complicated truth about their relationship with each other and the tension surrounding their differing opinions about their notorious daughter, Casey.",
  executiveProducers: ["Matt Sprouse", "Ben Shank"],
};

export const throupledProject: ProjectData = {
  title: "THROUPLED",
  dropDownOrder: 3,
  displayedOrder: 2,
  titleFragments: ["Throupled"],
  url: "throupled",
  imgUrl: "Troupled.jpg",
  duration: "60 x 8",
  releaseDate: "2023",
  synopsis:
    "THROUPLED is a celebratory exploration of modern dating in the 21st century, diving full throttle into this controversial new way to love that so many can’t even fathom. Through the eyes of average couples who have only dared to dream about it, but are now willing to take the leap, we’ll find out if going from couple to throuple can be their new normal.",
  executiveProducers: [
    "Matt Sprouse",
    "Sam Dean",
    "Joseph Cilona",
    "Michael Beck",
  ],
  directors: ["Tim Baker"],
  productionDesign: ["Tommy Rouse"],
};

export const whereWeCallHomeProject: ProjectData = {
  title: "Where We Call Home",
  dropDownOrder: 2,
  displayedOrder: 4,
  titleFragments: ["Where", "We Call", "Home"],
  url: "where-we-call-home",
  imgUrl: "WhereWeCallHome.jpg",
  duration: "12 Episodes",
  releaseDate: "2022",
  synopsis:
    "In the second season of Where We Call Home, homeowners and designers reveal how commercial properties and unconventional spaces were reimagined into modern homes through adaptive reuse renovations across the United States.",
  executiveProducers: ["Matt Sprouse", "Lauren Ranzino"],
  directors: ["Benjamin Mathes", "Priscilla Rubio"],
  directorsOfPhotography: ["Daley Hake", "Michael Fitzpatrick Lawrence Jr."],
};

export const williamsFamilyCabinProject: ProjectData = {
  title: "The Williams Family Cabin",
  dropDownOrder: 1,
  displayedOrder: 1,
  titleFragments: ["The", "Williams", "Family", "Cabin"],
  url: "williams-family-cabin",
  imgUrl: "WilliamsFamilyCabin.jpg",
  duration: "30 x 6",
  releaseDate: "2022",
  synopsis:
    "In The Williams Family Cabin, a designer and her musician husband turn an old cabin outside of Nashville, Tennessee into a family retreat while balancing four kids, an antique shop and a successful music career.",
  executiveProducers: ["Matt Sprouse", "Lauren Ranzino", "Amber Engelmann"],
  directorsOfPhotography: ["Eric Porter", "Michael Fitzpatrick Lawrence Jr."],
};

export const inWithTheOldProject: ProjectData = {
  title: "In With The Old",
  dropDownOrder: 6,
  displayedOrder: 6,
  titleFragments: ["In With", "The Old"],
  url: "in-with-the-old",
  imgUrl: "InWithTheOld.jpg",
  duration: "60 x 1",
  releaseDate: "2023",
  synopsis:
    "In this installment of the home renovation anthology series In With The Old, a furniture designer and his artist wife converts an untouched 1881 one-room schoolhouse in Hudson, NY into a three-bedroom two bathroom home for their family.",
  executiveProducers: ["Matt Sprouse", "Lauren Ranzino", "Lona Smith"],
  directorsOfPhotography: ["Michael Fitzpatrick Lawrence Jr."],
};

export const chefsGuidProject: ProjectData = {
  title: "A Chef's Guide To Italian Cooking",
  dropDownOrder: 4,
  displayedOrder: 5,
  titleFragments: ["A Chef's", "Guide To", "Italian", "Cooking"],
  url: "chefs-guide-to-italian-cooking",
  imgUrl: "AChefsGuidetoItalianCooking.jpg",
  duration: "9 Episodes",
  releaseDate: "2022",
  synopsis:
    "In Magnolia Workshops: A Chef’s Guide to Italian Cooking, James Beard Award-winning national bestselling cookbook author and former food director for Bon Appétit, Carla Lalli Music, teaches viewers to cook Italian food with confidence as she demonstrates how to prepare recipes from her family’s kitchen.",
  executiveProducers: ["Matt Sprouse", "Lauren Ranzino"],
  directorsOfPhotography: ["Daley Hake"],
  culinaryProducer: ["Martha Tinkler"],
};

/**
 * Order dictates order shown on various screens
 */

export const projects: ProjectData[] = [
  williamsFamilyCabinProject,
  throupledProject,
  caseyAnthonyProject,
  whereWeCallHomeProject,
  chefsGuidProject,
  inWithTheOldProject,
];
