import { Link } from "react-router-dom";
import {
  closeProjectPopover,
  toggleBurgerPopover,
} from "../util/domManipulator";
import { projects } from "../data/projects";
import {
  DescriptionLinksText,
  HeroSubDescriptionText,
  HeroText,
} from "./Typography";

export const MobilePopOver = () => {
  return (
    <div
      id="mobileMenuPopover"
      className="relative flex-col w-full hidden -z-10 bg-black text-white pt-[62px]"
    >
      <div className="pb-[39px]">
        <Link to="/featured-projects" onClick={toggleBurgerPopover}>
          <div className="overflow-hidden">
            <HeroText text="Featured" color="Gradient" animations="None" />
          </div>
          <div className="overflow-hidden">
            <HeroText text="Projects" color="Gradient" animations="None" />
          </div>
        </Link>
      </div>
      <div className="pb-[39px]">
        <Link to="/about" onClick={toggleBurgerPopover}>
          <div className="overflow-hidden">
            <HeroText text="About" color="Plain" animations="None" />
          </div>
        </Link>
      </div>
      <div className="pb-[39px]">
        <Link to="/contact" onClick={toggleBurgerPopover}>
          <div className="overflow-hidden">
            <HeroText text="Contact" color="Plain" animations="None" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export const ProjectPopOver = () => {
  const titlesAndUrls = projects.map((project) => ({
    title: project.title,
    url: project.url,
    order: project.dropDownOrder,
    displayedOrder: project.displayedOrder,
  }));

  const sortedTitlesAndUrls = titlesAndUrls.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });

  const projectLinks = sortedTitlesAndUrls.map((project, index) => {
    return (
      <div key={index} className="h-fit">
        <div className="overflow-hidden">
          <DescriptionLinksText
            text={".0" + project.displayedOrder}
            color="Plain"
            animations="None"
          />
        </div>
        <Link
          to={"/featured-project/" + project.url}
          onClick={closeProjectPopover}
        >
          <div className="overflow-hidden uppercase">
            <HeroSubDescriptionText text={project.title} />
          </div>
        </Link>
      </div>
    );
  });

  return (
    <div
      id="projectMenuPopover"
      className="absolute top-[196px] left-0 flex-col hidden -z-10  text-white h-[212px] w-full"
    >
      <div
        className="-mt-[80px] px-[102px] bg-black"
        onMouseLeave={closeProjectPopover}
      >
        <div className="h-[80px]" />
        <div className="grid grid-cols-2 grid-rows-3 pb-[53px] gap-y-[21px] h-auto">
          {projectLinks}
        </div>
      </div>
    </div>
  );
};
