import React from "react";
import { Link } from "react-router-dom";
import { photoFolder, ProjectData } from "../data/projects";
import { DescriptionText } from "./Typography";
import { LazyLoadImage } from "react-lazy-load-image-component";

export type ProjectProps = {
  projectData: ProjectData;
};

export const ProjectCover = (props: ProjectProps) => {
  return (
    <>
      <Link
        to={"/featured-project/" + props.projectData.url}
        className="group flex flex-col cursor-pointer"
      >
        <div className="pb-[14px] lg:pb-[17px]">
          <div className="block">
            <LazyLoadImage
              wrapperClassName="object-contain w-full h-full object-[50% 50%] hover:animate-pulse"
              src={"./" + photoFolder + props.projectData.imgUrl}
              placeholderSrc={
                "./" + photoFolder + "/placeholder/" + props.projectData.imgUrl
              }
              alt={props.projectData.title + " cover photo"}
            />
          </div>
        </div>
        <div className="relative flex flex-row items-center">
          <span className="group-hover:text-orange-400">
            <DescriptionText
              text={props.projectData.title}
              animations="None"
              color="Plain"
            />
          </span>
          <div className="grow-[2] ml-2 bg-white h-[1px] group-hover:bg-orange-400" />
        </div>
      </Link>
    </>
  );
};
