import { MainContent } from "../components/MainComponent";
import logo from "../assets/WME_Logo.png";
import {
  DescriptionText,
  HeroDescriptionText,
  HeroText,
} from "../components/Typography";
import React from "react";
export const Contact = () => {
  React.useEffect(() => {
    document.title = "Contact | York and Wilder";
  }, []);

  return (
    <MainContent backToTop={false}>
      <div className="flex flex-col flex-nowrap pt-[137px] lg:pt-[100px] gap-y-[25px] lg:gap-y-[89px]">
        <div>
          <div className="overflow-hidden hidden lg:block">
            <HeroText
              text="Get In Touch"
              color="Gradient"
              animations="animate-up-2"
            />
          </div>
          <div className="overflow-hidden lg:hidden">
            <HeroText
              text="Get In"
              color="Gradient"
              animations="animate-up-2"
            />
          </div>
          <div className="overflow-hidden lg:hidden">
            <HeroText text="Touch" color="Gradient" animations="animate-up-2" />
          </div>
        </div>
        <div className="flex justify-start flex-col lg:flex-row flex-wrap lg:flex-nowrap lg:gap-x-[140px] lg:gap-y-0">
          <div className="my-auto pb-[39px] lg:pb-0">
            <div className="overflow-hidden h-fit">
              <u>
                <a href="mailto:office@yorkandwilder.com">
                  <DescriptionText
                    text="office@yorkandwilder.com"
                    color="Plain"
                    animations="animate-up-2"
                  />
                </a>
              </u>
            </div>
          </div>
          <div className="text-left pb-[39px] lg:pb-0 lg:text-center">
            <div className="overflow-hidden h-fit">
              <HeroDescriptionText
                text="67 35th Street, 5-B503"
                animations="animate-up-2"
                color="White"
              />
            </div>
            <div className="overflow-hidden h-fit">
              <HeroDescriptionText
                text="Brooklyn, NY 11232"
                animations="animate-up-2"
                color="White"
              />
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <div className="pt-[7px]">
              <div className="overflow-hidden h-fit">
                <DescriptionText
                  text="Represented By"
                  color="Plain"
                  animations="animate-up-2"
                />
              </div>
            </div>
            <div className="pb-[7px]">
              <div className="overflow-hidden h-fit ">
                <a
                  href="http://www.wmeagency.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="animate-up-2 w-[77px] h-[24px]"
                    src={logo}
                    alt="WME Logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );
};
