/**
 * 100 vh - (header height + footer height)
 */

type ScreenExtenderProps = {
  children: React.ReactNode; // 👈️ type children
};

export const ScreenExtender = (props: ScreenExtenderProps) => {
  return (
    <div className="min-h-vhMinusHeaderAndFooterIphone sm:min-h-vhMinusHeaderAndFooterSmallerHeader lg:min-h-vhMinusHeaderAndFooter px-[28px] sm:px-[102px]">
      {props.children}
    </div>
  );
};
