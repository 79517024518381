export type Person = {
  name: string;
  tittle: string;
  bio: string;
  imdbLink: string;
  noteableCredits: string[];
};

export const MattSprouse: Person = {
  name: "Matt Sprouse",
  tittle: "President and Executive Producer",
  bio: "Prior to founding York & Wilder, Matt developed, sold, and produced award-winning television for over 20-years, most recently as Vice President of Programming at Jupiter Entertainment during the lead up to its acquisition by British media conglomerate SKY Studios. Matt launched York & Wilder in 2018 where he leads all aspects of business and creative strategy.",
  imdbLink: "https://www.imdb.com/name/nm1576113/",
  noteableCredits: [
    "Where We Call Home (Magnolia & HBO Max)",
    "American Murder Mystery (ID)",
    "Casey Anthony’s Parents Speak (A&E)",
    "Wild West Alaska (Animal Planet)",
    "Notorious (Oxygen)",
    "Gamechangers (ABC)",
  ],
};

export const MarnieSirota: Person = {
  name: "Marnie Sirota",
  tittle: "Senior Vice President of Production",
  bio: "Marnie comes to York & Wilder having held leadership roles on many of UK television’s most successful, big-format series. At York & Wilder, Marnie is responsible for managing the team that executes all aspects of production from contract negotiations, budgeting, staffing, to executive in charge duties during production, and finally master delivery of the program and accompanying assets.",
  imdbLink: "https://www.imdb.com/name/nm2032283/?ref_=nv_sr_srsg_0",
  noteableCredits: [
    "I’m a Celebrity…Get Me Out of Here! (ITV)",
    "Next Top Model (Sky)",
    "Big Brother UK (C4 & C5)",
    "Celebrity Big Brother UK (C4 & C5)",
    "I Am A Killer (Netflix)",
    "Ultimate Tag (FOX)",
  ],
};

export const LaurenRanzino: Person = {
  name: "Lauren Ranzino",
  tittle: "Vice President of Development and Current",
  bio: "Lauren began her career 15 years ago in casting and development but quickly shifted into post-production at Truly Original where she built a wealth of expertise telling entertaining and varied stories. At York & Wilder, Lauren is responsible for shepherding projects from inception through  the development and sales process, then overseeing the creative teams brought on to produce each series.",
  imdbLink: "https://www.imdb.com/name/nm2724355/?ref_=nv_sr_srsg_0",
  noteableCredits: [
    "Real Housewives of Atlanta (Bravo)",
    "Say Yes To the Dress (TLC)",
    "Don’t Be Tardy (Bravo)",
    "Williams Family, Cabin (Magnolia)",
    "Real Housewives of Miami (Bravo)",
    "Magnolia Workshops (Magnolia)",
  ],
};
