import { MainContent } from "../components/MainComponent";
import { ProjectCover } from "../components/ProjectCover";
import { DescriptionText, HeroText } from "../components/Typography";
import { projects } from "../data/projects";
import gif_desktop from "../assets/FeaturedProjects_Desktop.gif";
import gif_mobile from "../assets/FeaturedProjects_Mobile.gif";
import React from "react";

export const FeaturedProjects = () => {
  React.useEffect(() => {
    document.title = "Featured Projects | York and Wilder";
  }, []);

  const projectCovers = projects.map((project, index) => (
    <div key={index}>
      <ProjectCover projectData={project} />
    </div>
  ));

  return (
    <MainContent backToTop={true}>
      <div className="absolute flex right-0 top-0 w-[100vw] justify-end -z-10">
        <img
          className="hidden sm:block w-[calc(100%_-_100px)] lg:w-[1153px] h-[230px] lg:h-auto"
          src={gif_desktop}
          alt="project gif"
        />
        <img
          className="sm:hidden w-auto lg:w-[1153px] h-[220px] lg:h-auto"
          src={gif_mobile}
          alt="project gif"
        />
      </div>
      <div className="flex flex-col flex-nowrap pt-[10vh] pb-[54px] lg:pb-[89px]">
        <div className="pb-[5px]">
          <div className="overflow-hidden">
            <DescriptionText
              text="Now Showing"
              color="Plain"
              animations="animate-up-2"
            />
          </div>
        </div>
        <div className="pb-[48px] lg:pb-[92px]">
          <div className="overflow-hidden">
            <HeroText text="Featured" color="Plain" animations="animate-up-2" />
          </div>
          <div>
            <div className="overflow-hidden">
              <HeroText
                text="Projects"
                color="Plain"
                animations="animate-up-2"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-[80px] gap-y-[34px] lg:gap-y-[70px]">
          {projectCovers}
        </div>
      </div>
    </MainContent>
  );
};
