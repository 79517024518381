import { underlineAnimation } from "./animations";
import { DescriptionLinksText } from "./Typography";

export const Footer = () => {
  let displayClassName = "hidden sm:block ";
  const footerElement = (
    <footer
      id="footerNav"
      className={
        displayClassName +
        "sticky sm:static w-full text-center bottom-0  text-white h-[65px]"
      }
    >
      <div className="relative h-full border-t-white border-t-[1px] border-opacity-60 animate-fade-in-4">
        <div className="flex flex-col justify-center px-[28px] sm:px-[102px] h-[90%] sm:h-full">
          <div className="flex justify-end">
            <div className="overflow-hidden w-20 flex justify-end">
              <div className="hover:cursor-pointer relative w-max">
                <a
                  className={underlineAnimation}
                  href="mailto:office@yorkandwilder.com"
                >
                  <DescriptionLinksText
                    text="Mail"
                    color="Plain"
                    animations="animate-up-4"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="overflow-hidden w-20 flex justify-end">
              <div className="hover:cursor-pointer relative w-max">
                <a
                  className={underlineAnimation}
                  href="https://www.instagram.com/yorkandwilder/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DescriptionLinksText
                    text="Instagram"
                    color="Plain"
                    animations="animate-up-4"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );

  return footerElement;
};
