import logo from "../assets/YorkandWilderLogo.png";
import { Link } from "react-router-dom";
import {
  closeProjectPopover,
  closeBurgerPopover,
  openProjectPopover,
  toggleBurgerPopover,
} from "../util/domManipulator";
import { DescriptionLinksText } from "./Typography";
import { underlineAnimation } from "./animations";

export const Header = () => {
  return (
    <header className="bg-transparent text-white">
      <div className="flex flex-row flex-nowrap py-[35px] lg:py-[79px] px-[28px] lg:px-[102px]">
        <div className="grow-0 shrink-0">
          <div className="float-left text-left">
            <Link
              to="/"
              onClick={() => {
                closeBurgerPopover();
                closeProjectPopover();
              }}
            >
              <div className="overflow-hidden">
                <img
                  className="w-auto h-[24px] lg:h-[38px] animate-up-2 hover:cursor-pointer"
                  src={logo}
                  alt="logo"
                />
              </div>
            </Link>
          </div>
        </div>
        <div
          id="largerScreenView"
          className="hidden lg:block text-right grow-1 shrink-1 self-center w-full font-NeueHaasUnicaW1G"
        >
          <nav className="inline-flex justify-end w-auto">
            <div className="lg:pr-[30px]">
              <div
                className="hover:cursor-pointer relative"
                onMouseOver={openProjectPopover}
                onClick={closeProjectPopover}
              >
                <Link to="/featured-projects" className={underlineAnimation}>
                  <div className="overflow-hidden">
                    <DescriptionLinksText
                      text="Featured Projects"
                      color="Plain"
                      animations="animate-up-2"
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div className="lg:pr-[30px]">
              <div
                className="hover:cursor-pointer relative"
                onClick={closeProjectPopover}
              >
                <Link to="/about" className={underlineAnimation}>
                  <div className="overflow-hidden">
                    <DescriptionLinksText
                      text="About"
                      color="Plain"
                      animations="animate-up-2"
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div
              className="hover:cursor-pointer relative"
              onClick={closeProjectPopover}
            >
              <Link to="/contact" className={underlineAnimation}>
                <div className="overflow-hidden">
                  <DescriptionLinksText
                    text="Contact"
                    color="Plain"
                    animations="animate-up-2"
                  />
                </div>
              </Link>
            </div>
          </nav>
        </div>
        <div
          id="smallScreenView"
          className="flex lg:hidden text-right grow-1 shrink-1 self-center w-full flex-row-reverse z-20"
        >
          <nav className="inline-flex justify-end w-auto gap-x-3.5 pr-[4vw]">
            <div
              className="w-5 h-4 flex flex-col border-white hover:cursor-pointer hover:border-orange-400 justify-between"
              onClick={toggleBurgerPopover}
            >
              <i
                id="mobile-x-arrow"
                className="fa-solid fa-x hidden bg-clip-text bg-textGradient text-transparent text-center"
              />
              <div id="mobile-menu" className="border-t border-inherit" />
              <div id="mobile-menu" className="border-t border-inherit" />
              <div id="mobile-menu" className="border-t border-inherit" />
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};
