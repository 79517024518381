import React, { useEffect } from "react";
import { MainContent } from "../components/MainComponent";
import { ProjectProps } from "../components/ProjectCover";
import {
  DescriptionText,
  HeroDescriptionText,
  HeroText,
} from "../components/Typography";
import { photoFolder } from "../data/projects";

export const IndividualProjects = (props: ProjectProps) => {
  React.useEffect(() => {
    document.title = props.projectData.title + " | York and Wilder";
  }, [props.projectData.title]);
  const title = createStyledTitle(props.projectData.titleFragments);

  const directory = addDetails("Directed By", props.projectData.directors);

  const releaseDate = addDetails("Release Date", [
    props.projectData.releaseDate,
  ]);
  const duration = addDetails("Duration", [props.projectData.duration]);
  const executiveProducer = addDetails(
    "Executive Producers",
    props.projectData.executiveProducers
  );
  const directorsOfPhotography = addDetails(
    "Director of Photography",
    props.projectData.directorsOfPhotography
  );
  const culinaryProducer = addDetails(
    "Culinary Producer",
    props.projectData.culinaryProducer
  );
  const productionDesign = addDetails(
    "Production Design",
    props.projectData.productionDesign
  );

  const callback = function (entries: any) {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("play");
        entry.target.classList.remove("pause");
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const observer = new IntersectionObserver(callback);

  useEffect(() => {
    const targets: NodeListOf<Element> = document.querySelectorAll(".pause");
    targets.forEach((target: Element) => {
      observer.observe(target);
    });
  }, [observer]);

  return (
    <MainContent backToTop={true}>
      <div className="absolute right-0 lg:right-[15vw] top-0 lg:top-[154px]">
        <img
          className="object-contain w-[265px] lg:w-[619px] h-[398px] lg:h-[936px]"
          src={"../" + photoFolder + "/" + props.projectData.imgUrl}
          alt={props.projectData.title + " cover photo"}
        />
      </div>
      <div className="flex flex-col flex-nowrap pb-10 pt-[232px] lg:pt-0">
        <div className="lg:min-h-[929px]">
          <div className="relative pb-[25px] lg:pb-[58px] lg:min-h-[360px] flex flex-col lg:justify-end">
            {title}
          </div>
          <div className="grid gap-4 grid-cols-2 lg:grid-cols-1 lg:pt-0 gap-y-8 lg:gap-y-7">
            {releaseDate}
            {duration}
            {directory}
            {executiveProducer}
            {directorsOfPhotography}
            {culinaryProducer}
            {productionDesign}
          </div>
        </div>
        <div className="m-auto pt-[50px] lg:pt-[81px] text-center">
          <div className="pb-[22px] lg:pb-[32px]">
            <div className="overflow-hidden">
              <HeroDescriptionText
                text="Synopsis"
                animations="animate-up-8 pause"
                color="text-orange-400"
              />
            </div>
          </div>
          <div className="pb-[49px] lg:pb-[92px]">
            <div className="animate-fade-in-6 pause lg:text-left">
              <HeroDescriptionText
                text={props.projectData.synopsis}
                animations="None"
                color="White"
              />
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );
};

function createStyledTitle(titleParts: string[]) {
  return titleParts.map((value, index) => {
    return (
      <div key={index} className="overflow-hidden">
        <HeroText text={value} color="Plain" animations="animate-up-2" />
      </div>
    );
  });
}

function addDetails(title: string, details?: string[]) {
  if (details) {
    const detailsCollection = details.map((detail, index) => (
      <div key={index} className="overflow-hidden">
        <DescriptionText
          text={detail}
          animations="animate-up-6"
          color="Plain"
        />
      </div>
    ));
    return (
      <div>
        <div className="overflow-hidden uppercase">
          <DescriptionText
            text={title}
            animations="animate-up-6"
            color="Color"
          />
        </div>
        {detailsCollection}
      </div>
    );
  } else {
    return <React.Fragment />;
  }
}
