import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { MobilePopOver, ProjectPopOver } from "./components/PopOver";
import { ScreenExtender } from "./components/ScreenExtender";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { FeaturedProjects } from "./pages/FeaturedProjects";
import { Home } from "./pages/Home";
import { IndividualProjects } from "./pages/IndividualProject";
import { projects } from "./data/projects";
import "./index.css";

function App() {
  const projectUrls = projects.map((project, index) => (
    <Route
      key={index}
      path={"/featured-project/" + project.url}
      element={<IndividualProjects projectData={project} />}
    />
  ));

  return (
    <>
      <Header />
      <ScreenExtender>
        <MobilePopOver />
        <ProjectPopOver />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/featured-projects" element={<FeaturedProjects />} />
          {projectUrls}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ScreenExtender>
      <Footer />
    </>
  );
}

export default App;
